import React, { useState, useContext } from 'react';
import { Drawer } from '@mui/material';
import styles from './Timeline.styles';
import DocumentViewer from './DocumentViewer';
import TimelineHeader from './TimelineHeader';
import TimelineOverview from './TimelineOverview';
import TimelineDetails from './TimelineDetails';
import GlobalStore from '../../../../store';

interface TimelineProps {
  headerText: string;
  sectionData: any;
  details: any;
  setShowTimeline: any;
  meetingId: string;
}

const Timeline = ({
  headerText,
  sectionData,
  details,
  setShowTimeline,
  meetingId
}: TimelineProps) => {
  const [openDocument, setOpenDocument] = useState(false);
  const [documentTitle, setDocumentTitle] = useState('');
  const [documentPath, setDocumentPath] = useState('');
  const [documentStartDate, setDocumentStartDate] = useState('');
  const { state } = useContext(GlobalStore) as any;

  const getFormattedSectionData = () => {
    const metadata = [
      {
        label: '',
        value: details.rapporteur
      },
      {
        label: 'Co-Rapp',
        value: details.co_rapporteur
      },
      {
        label: 'PRAC Rapp',
        value: details.prac_rapporteur
      }
    ]?.filter(each => each?.value && each?.value !== 'NA');

    if (metadata?.length > 0) {
      return [
        ...sectionData,
        {
          label: 'Rapporteur',
          type: 'piped-text',
          metadata
        }
      ];
    }
    return sectionData;
  };

  const getDrawerWidth: any = () => {
    if (openDocument) {
      return '46%';
    }
    if (state?.chatRiaOpen) {
      return '0';
    }
    return '50%';
  };

  return (
    <Drawer
      anchor='right'
      open
      transitionDuration={500}
      hideBackdrop={false}
      sx={{
        ...styles.timeline.drawer,
        '& >.MuiDrawer-paper': {
          width: {
            md: getDrawerWidth()
          },
          overflow: 'hidden',
          boxShadow: '-0.1em 0.1em 5em #33b1874a'
        }
      }}>
      <TimelineHeader setShowTimeline={setShowTimeline} activeIngredient={headerText} />
      <TimelineOverview sectionData={getFormattedSectionData()} details={details} />
      <TimelineDetails
        details={details}
        setOpenDocument={setOpenDocument}
        setDocumentPath={setDocumentPath}
        setDocumentTitle={setDocumentTitle}
        setDocumentStartDate={setDocumentStartDate}
        meetingId={meetingId}
      />

      {openDocument && (
        <DocumentViewer
          openDocument={openDocument}
          setOpenDocument={setOpenDocument}
          documentTitle={documentTitle}
          setDocumentTitle={setDocumentTitle}
          documentPath={documentPath}
          documentStartDate={documentStartDate}
        />
      )}
    </Drawer>
  );
};

export default React.memo(Timeline);
