import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Chip } from '@mui/material';
import {
  ariaCardResultsMapping,
  SOURCE_WITH_SUBTITLE,
  TAG_KEYS
} from '../../../pages/Aria/utils/constants';
import customTitleCase from '../../../utils/titleCase';
import styles from '../../../pages/Aria/styles/AriaResult.styles';
import { getAriaResultTitle } from '../../../pages/Aria/components/AriaResult';

import {
  LABEL_COLOR,
  LABEL_TOOLTIP_TITLE,
  sourceFlagMapping,
  sourceFlagMappingTooltip
} from '../../../pages/SearchResults/constants';

const AriaDocumentMeta = ({ source, item }: any) => {
  const FlagComponent = sourceFlagMapping[source];
  const flagTooltipTitle = sourceFlagMappingTooltip[source];
  const title =
    // this check is necessary to maintain the title in same case as source
    source === 'pdco' || source === 'prac'
      ? getAriaResultTitle(item[ariaCardResultsMapping[source]?.title], item, source)
      : customTitleCase(
          getAriaResultTitle(item[ariaCardResultsMapping[source]?.title], item, source)
        );
  return (
    <Stack direction='column' width='95%'>
      <Stack direction='row' spacing={1} alignItems='center'>
        <Tooltip
          title={`${ariaCardResultsMapping[source]?.displayName}: ${title}}`}
          placement='bottom-start'>
          <Typography variant='h3' sx={styles.title} noWrap>
            {title}
          </Typography>
        </Tooltip>
        {SOURCE_WITH_SUBTITLE.includes(source) && (
          <Tooltip title={item.trade_name} placement='bottom-start'>
            <Typography component='span' variant='h3' noWrap sx={styles.title}>
              {item.trade_name && `(${item.trade_name || ''})`}
            </Typography>
          </Tooltip>
        )}
      </Stack>
      <Stack direction='row' display='flex' justifyContent='flex-start' alignItems='center'>
        {Object.entries(ariaCardResultsMapping[source]).map(([key, value]: any) => {
          if ((key === 'date' || key === 'date1') && item[value.type]?.includes('1900-01-01')) {
            return null;
          }
          return TAG_KEYS[source].includes(key) && item[value.type] ? (
            <Stack direction='row' alignItems='center' flexWrap='wrap' key={key}>
              <Tooltip
                key={key}
                title={
                  key === 'date' || key === 'date1'
                    ? `${value.displayName}: ${item[value.type]?.split('T')[0] || 'UNKNOWN'}`
                    : `${value.displayName}: ${item[value.type] || 'UNKNOWN'}`
                }
                placement='bottom-end'>
                <Typography noWrap variant='subtitle2' sx={styles.tagText}>
                  {key === 'date'
                    ? item[value.type]?.split('T')[0] || 'UNKNOWN'
                    : item[value.type] || 'UNKNOWN'}
                </Typography>
              </Tooltip>
              <Typography sx={styles.text} noWrap>
                •
              </Typography>
            </Stack>
          ) : null;
        })}
        <Tooltip title={flagTooltipTitle}>
          {FlagComponent && <FlagComponent sx={{ width: '18px', height: '18px' }} />}
        </Tooltip>
        {item?.labels &&
          item?.labels.map((label: string) => {
            const labelKey = label.toLowerCase();
            const labelColor = LABEL_COLOR[labelKey];
            const labelTooltipTitle = LABEL_TOOLTIP_TITLE[labelKey];
            return labelColor ? (
              <Tooltip title={labelTooltipTitle}>
                <Chip
                  label={label}
                  key={label}
                  sx={{
                    ...styles.metadataChip,
                    bgcolor: labelColor
                  }}
                />
              </Tooltip>
            ) : null;
          })}
      </Stack>
    </Stack>
  );
};

export default AriaDocumentMeta;
