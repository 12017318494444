import { TopicProps, SectionDataItem } from './types';

export const shortDateFormat = (date: string) =>
  new Date(date).toLocaleString('en-US', {
    month: 'short',
    year: 'numeric'
  });

export const getDetailsText = (isSummaryAvailable: boolean, isExpanded: boolean) => {
  if (!isSummaryAvailable) {
    return 'No Details available from this Source';
  }
  if (isExpanded) {
    return 'Hide Details';
  }
  return 'View Details';
};

export const getSectionDetails = (topic: TopicProps) => {
  const sectionDetails = [
    {
      label: 'Indication',
      mainText: topic.indication,
      type: 'text'
    },
    {
      label: 'Scope',
      mainText: topic.scope,
      type: 'text'
    }
  ].filter((item: any) => item.mainText !== 'NA' && !!item.mainText) as SectionDataItem[];

  if (
    (topic.sponsor_name && topic.sponsor_name !== 'NA') ||
    (topic.brand_name && topic.brand_name !== 'NA')
  ) {
    let toolTipText = '';

    const tags = Array.isArray(topic.tagged_words) ? topic.tagged_words : ['NA'];

    if (topic.sponsor_name && topic.sponsor_name !== 'NA') {
      toolTipText += `Sponsor: ${topic.sponsor_name}`;
    }
    if (topic.brand_name && topic.brand_name !== 'NA') {
      toolTipText += `; Brand Name: ${topic.brand_name.toUpperCase()}`;
    }
    if (tags?.length && tags?.[0] !== 'NA') {
      toolTipText += `; ${tags.join('; ')}`;
    }

    sectionDetails.unshift({
      label: 'Sponsor',
      mainText: topic.sponsor_name,
      boldText: topic.brand_name,
      tagTexts: tags,
      type: 'emphasised-text',
      toolTipText
    });
  }

  return sectionDetails;
};
