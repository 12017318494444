import React, { useMemo } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Section from './Section';

import styles from '../../EMAMeetings/styles/Meeting.styles';
import Metadata from './Metadata';
import { SectionListProps, TopicProps } from '../types';
import { getSectionDetails } from '../utils';

const SectionList: React.FC<SectionListProps> = ({
  sections,
  sectionTitle,
  meetingId,
  onSectionExpand,
  expanded
}) => {
  const formattedSectionData = useMemo(() => {
    return (topic: TopicProps) => getSectionDetails(topic);
  }, []);

  return (
    <Stack spacing={2} id={sectionTitle} sx={{ ...styles.sectionsContainer, pb: 3 }}>
      <Stack spacing={1} aria-label='section' margin={0}>
        <Typography sx={styles.sectionKey}>{sectionTitle}</Typography>
      </Stack>
      {sections?.map((topic: TopicProps, index: number) => {
        const sectionDetails = formattedSectionData(topic);
        const tagText = topic.highlight_data.tag || 'NA';
        const typeText =
          topic.highlight_data.category_display_name === 'Other' ||
          !topic.highlight_data.category_display_name
            ? 'NA'
            : topic.highlight_data.category_display_name;
        return (
          <Section
            // eslint-disable-next-line react/no-array-index-key
            key={topic.sub_section_number + index}
            headerText={topic.active_ingredient}
            sectionDetails={sectionDetails}
            topic={topic}
            index={index}
            expanded={expanded}
            Metadata={
              tagText.toLowerCase() !== 'na' || typeText.toLowerCase() !== 'na' ? (
                <Metadata
                  text={tagText.toLowerCase() !== 'na' ? tagText : ''}
                  type={typeText.toLowerCase() !== 'na' ? typeText : ''}
                />
              ) : null
            }
            onSectionExpand={onSectionExpand}
            meetingId={meetingId}
            productNumber={topic.modified_product_number}
            actionDetails={
              Array.isArray(topic.action_summary)
                ? {
                    title: topic.action_type,
                    actions: topic.action_summary
                  }
                : null
            }
          />
        );
      })}
    </Stack>
  );
};

export default React.memo(SectionList);
